<template>
  <div class="reports">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'Reports'
}
</script>

<style scoped>
.reports {
    min-height:76%;
    display: flex;
}
</style>
